@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400&family=Outfit:wght@500&display=swap');

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
}

::-moz-selection {
  color: #fff;
  background: rgba(68, 68, 68, 0.8);
}

::selection {
  color: #fff;
  background: rgba(68, 68, 68, 0.8);
}

.App {
  text-align: center;
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: #000;
  /* background: -webkit-linear-gradient(rgba(22, 11, 33, 0.9), rgba(11, 0, 22, 0.9)), url("../public/background.webp"); */
  background: -webkit-linear-gradient(rgba(50, 50, 75, 1), rgba(0, 15, 25, 1));
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
}

.App-header {
  position: relative;
  z-index: 2;
  color: #fff;
}

.locale-select {
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 4;
  padding: 10px 20px;
  color: #007BFF;
  background-color: transparent;
  border: 1px solid #007BFF;
  border-radius: 5px;
  cursor: pointer;
}

.particles {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}

.particle {
  position: absolute;
  width: 0.35rem;
  height: 0.35rem;
  border-radius: 50%;
  background-color: white;
  transform-origin: center;
  -webkit-filter: blur(0.1rem);
  filter: blur(0.1rem);
  z-index: 0;
}

.profile-card {
  background: rgba(255,255,255,0.9);
  padding: 2rem 3rem;
  border-radius: 8px;
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.75);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 640px;
  margin: 2rem;
  z-index: 1;
  max-height: calc(100vh - 8rem);
  overflow-y: auto;
  position: relative;
}

.profile-card::-webkit-scrollbar {
  width: 6px;
}

.profile-card::-webkit-scrollbar-track {
  background: transparent;
}

.profile-card::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.profile-card::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}

.profile-card {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

.scroll-indicator {
  position: absolute;
  bottom: 15px;
  right: 10px;
  width: 30px;
  height: 30px;
  /* background-color: #007BFF; */
  border: 1px solid #007BFF;
  color: #007BFF;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  z-index: 2;
  animation: bounce 1s infinite;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.scroll-indicator::before {
  content: "scroll";
  font-variant: small-caps;
  font-size: 10px;
  margin-top: -30px;
  line-height: 3;
}

.scroll-indicator.hidden {
  opacity: 0; /* Hide the indicator when scrolled */
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}


.profile-info {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #798191;
}

.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #eee;
}

.profile-text h1 {
  font-family: 'Outfit', sans-serif;
  font-size: 4rem;
  font-weight: 500;
  letter-spacing: -.015em;
  line-height: 1;
  margin-bottom: 10px;
  color: #2E2E48;
}

.profile-text h1.japanese {
  font-size: 3rem;
}

.intro-video {
  width: 100%;
  max-width: 600px;
  display: block;
  margin: 20px auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


.button-group {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 15px;
}

.download-button, .cta-button {
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.download-button:hover, .cta-button:hover {
  background-color: #2292FF;
}

.case-studies {
  width: 100%;
  margin-top: 30px;
}

.case-studies h2 {
  font-family: 'Outfit', sans-serif;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: -.015em;
  line-height: 1;
  margin-bottom: 25px;
  color: #2E2E48;
}

.case-study {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 10px;
  padding: 10px 0 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.case-study-text {
  width: 60%;
  color: #798191;
}

.case-study-text h3 {
  font-size: 1.5rem;
  color: #2E2E48;
}

.case-study-tags {
  margin-top: 10px;
}

.badge {
  display: inline-block;
  padding: 5px 10px;
  margin-right: 8px;
  background-color: #2E2E48;
  color: white;
  border-radius: 20px;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.tech-used {
  color: #2E2E48;
  font-weight: bold;
}

.case-study-image {
  width: 35%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.case-study-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}


.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
}

.close-button {
  float: right;
  cursor: pointer;
}

form {
  display: flex;
  flex-direction: column;
}

input, textarea {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button[type="submit"] {
  background-color: #007BFF;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.message-toast {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  z-index: 4;
}

.message-toast.success {
  background-color: #4bb543;
}

.message-toast.error {
  background-color: #d9534f;
}

@media (max-width: 480px) {
  .profile-card {
    padding: 2rem;
  }

  .profile-info {
    flex-direction: column;
  }

  .profile-text h1 {
    margin-top: 0;
    font-size: 2rem;
  }

  .profile-text h1.japanese {
    font-size: 1.5rem;
  }
}